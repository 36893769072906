
import PrivateHeader from "./Header.vue";
import Vue from "vue";

export default Vue.extend({
	name: "PrivatePois",
	props: {},
	components: { PrivateHeader },
	data: () => ({}),
	created() {},
	async mounted() {},
	computed: {},
	methods: {},
});
