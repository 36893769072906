
import Vue from "vue";
import TitleWelcome from "../../../components/Header/TitleWelcome.vue";
import BreadCrumbs from "../../../components/Content/BreadCrumbs.vue";
import { last } from "lodash";

export default Vue.extend({
	name: "PrivatePoisHeader",
	props: {},
	components: {
		TitleWelcome,
		BreadCrumbs,
	},
	data: () => ({
		title: String,
	}),

	created() {},

	mounted() {},

	computed: {
		getTitle() {
			let self = this;
			const result: any = last(self.$route.matched);
			return result.meta.breadcrumb;
		},
	},

	methods: {},
});
